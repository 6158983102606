import React from "react"

const Klizna2 = () => {
  return (
    <table className="table is-bordered is-hoverable">
      <tbody>
        <tr className="has-background-link has-text-white">
          <td colSpan={6}>CD-60</td>
        </tr>
        <tr>
          <td>
            Šina
            <br />
            profila
          </td>
          <td>
            Debljina lima
            <br />S (mm)
          </td>
          <td>
            Težina G<br />
            (kg/m)
          </td>
          <td colSpan={2}>
            Dužina profila
            <br />L (mm)
          </td>
          <td>
            Pakovanje
            <br />
            (kom)
          </td>
        </tr>
        <tr>
          <td>P001.0462</td>
          <td>0,50</td>
          <td>0,483</td>
          <td>3000</td>
          <td>4000</td>
          <td>108</td>
        </tr>
        <tr>
          <td>P001.0486</td>
          <td>0,62</td>
          <td>0,579</td>
          <td>3000</td>
          <td>4000</td>
          <td>108</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Klizna2
