import React from "react"

const Klizna7 = () => {
  return (
    <table className="table is-bordered is-hoverable">
      <tbody>
        <tr className="has-background-link has-text-white">
          <td colSpan={6}>CW-100</td>
        </tr>
        <tr>
          <td>
            Šina
            <br />
            profila
          </td>
          <td>
            Debljina lima
            <br />S (mm)
          </td>
          <td>
            Težina G<br />
            (kg/m)
          </td>
          <td colSpan={2}>
            Dužina profila
            <br />L (mm)
          </td>
          <td>
            Pakovanje
            <br />
            (kom)
          </td>
        </tr>
        <tr>
          <td>P001.0491</td>
          <td>0,50</td>
          <td>0,785</td>
          <td>3000</td>
          <td>4000</td>
          <td>48</td>
        </tr>
        <tr>
          <td>P001.0509</td>
          <td>0,62</td>
          <td>0,942</td>
          <td>3000</td>
          <td>4000</td>
          <td>48</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Klizna7
