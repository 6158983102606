import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"

import Klizna1 from "../../components/tables/Klizna1"
import Klizna2 from "../../components/tables/Klizna2"
import Klizna4 from "../../components/tables/Klizna4"
import Klizna5 from "../../components/tables/Klizna5"
import Klizna6 from "../../components/tables/Klizna6"
import Klizna7 from "../../components/tables/Klizna7"
import Klizna8 from "../../components/tables/Klizna8"

import Klizna1Img from "../../images/klizne1.png"

import Klizna2Img from "../../images/klizne2.png"
import Klizna3Img from "../../images/klizne3.png"
import Klizna4Img from "../../images/klizne4.png"
import Klizna5Img from "../../images/klizne5.png"
import Klizna6Img from "../../images/klizne6.png"
import Klizna7Img from "../../images/klizne7.png"
import Klizna8Img from "../../images/klizne8.png"

import Img1 from "../../images/klizne1.jpg"
import Img2 from "../../images/klizne2.jpg"
import Img3 from "../../images/klizne3.jpg"
import Img4 from "../../images/klizne4.jpg"
import Klizna3 from "../../components/tables/Klizna3"
import Head from "../../components/head"

const KlizneSine = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
    {
      original: `${Img4}`,
      thumbnail: `${Img4}`,
    },
  ]
  return (
    <Layout>
      <Head title="CD/UD Profili" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">CD/UD Profili</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          CD/UD Profili
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>CD/UD Profili</strong> lzrađuju se od pocinkovanog lima
                i to u dve debljine 0,5 i 0,6mm. Postupak za izradu je hladno
                oblikovanje i beskonačne trake, koja se vrši na linijama za
                profilisanje. Profili su izvedeni tako da je obezbeđena
                maksimalna tačnost dimenzija čime se postiže propisana čvrstina
                izlaznog profila. Profili se pakuju po završenom valjanju u
                standardne pakete, naznačene u tabelama.
              </p>
              <br />
              <p>
                Limovi od kojih se izrađuju profili su kvalitetni DX51 D Zn, sa
                zateznom čvrstoćom u granicama između 350 - 400 N/mm2, nanosom
                cinka od 100-140 g/m2.
              </p>
              <div className="columns">
                <div className="column">
                  <br />
                  <strong>Napomena:</strong>
                  <br />
                  <strong>
                    U narednim tabelama i crtezima prikazacemo kompletno
                    završene profile, međutim trenutno iste nudimo bez
                    perforacije.
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <section
            className="tables-section container"
            data-aos="slide-up"
            data-aos-duration="900"
          >
            <div className="columns is-multiline is-flex tables-columns">
              <div className="column is-full-mobile">
                <Klizna1 />
                <img src={Klizna1Img} alt="" />
              </div>
              <div className="column is-full-mobile">
                <Klizna2 />
                <img src={Klizna2Img} alt="" />
              </div>
            </div>
            <div className="columns is-flex tables-columns">
              <div className="column is-full-mobile">
                <Klizna3 />
                <img src={Klizna3Img} alt="" />
              </div>
              <div className="column is-full-mobile">
                <Klizna4 />
                <img src={Klizna4Img} alt="" />
              </div>
            </div>
            <div className="columns is-flex tables-columns">
              <div className="column is-full-mobile">
                <Klizna5 />
                <img src={Klizna5Img} alt="" />
              </div>
              <div className="column is-full-mobile">
                <Klizna6 />
                <img src={Klizna6Img} alt="" />
              </div>
            </div>
            <div className="columns is-flex tables-columns">
              <div className="column is-full-mobile">
                <Klizna7 />
                <img src={Klizna7Img} alt="" />
              </div>
              <div className="column is-full-mobile">
                <Klizna8 />
                <img src={Klizna8Img} alt="" />
              </div>
            </div>
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default KlizneSine
